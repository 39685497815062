<template>
  <div>
    <div>
      <el-row class="bgW">
      <el-col :span="20">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          
        </el-form>
      </el-col>
      <el-col :span="4" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
      </el-row>


      <!--列表-->
      <el-row class="mt30">
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            height="630"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="市场" prop="shop_market" width='80'/>
            <ElTableColumn label="门店" prop="shop_name" />
            <!-- <ElTableColumn label="预备会员顾客" prop="cj_num" /> -->

            <ElTableColumn label="预备会员顾客" :render-header="renderHeader">
              <template slot-scope="{ row }">
                <div class="hand" @click="clickBtn(row,'cj_num')">{{row.cj_num}}</div>
              </template>
            </ElTableColumn>

            <ElTableColumn label="新增会员数" prop="xz_huiyuan_num" :render-header="renderHeader"/>
            <ElTableColumn label="入会率" prop="ruhuilv" />
            <ElTableColumn label="会员消费总数" prop="vip_pay_all" />
            <ElTableColumn label="顾客消费总数" prop="pay_all" />
            <ElTableColumn label="会员贡献度" prop="huiyuan_gongxiandu" />
            <ElTableColumn label="白玉兰" prop="xzw_huiyuan_num" />
            <ElTableColumn label="会员消费（白）" prop="wvip_pay_all" />
            <ElTableColumn label="会员贡献度（白）" prop="huiyuan_gongxiandu_w" />
            <ElTableColumn label="紫玉兰" prop="xzp_huiyuan_num" />
            <ElTableColumn label="会员消费（紫）" prop="pvip_pay_all" />
            <ElTableColumn label="会员贡献度（紫）" prop="huiyuan_gongxiandu_p" />
            <ElTableColumn label="金玉兰" prop="xzg_huiyuan_num" />
            <ElTableColumn label="会员消费（金）" prop="gvip_pay_all" />
            <ElTableColumn label="会员贡献度（金）" prop="huiyuan_gongxiandu_g" />
          </el-table>
          <!-- <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
        </el-col>
      </el-row>


      
    </div>
  </div>
</template>
<script>
import { getLastDay } from '@/utils';
import pagination from "@/components/Pagination";
import { huiyuan_tongji } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      online: [], //线上进店
      offline: [], //线下进店
      loading:false,
      exportS: false,
    };
  },
  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01' 
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
    
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      huiyuan_tongji({
        ...this.search,
        customer_id: this.clienId,
        ...this.page
      }).then(res => {
        this.list = res;
        // this.page.total = res.dataCount;
      });
    },
    // 导出
    exportExcel() {
      this.exportS = true
      shopDk({...this.search,export:1}).then(res => {
        window.open(res.data.url);
        this.exportS = false
      });
    },
    // 查看预备会员明细
    clickBtn(row,type){
      // console.log(type);
      const query = {
        type: type,
        shop_id: row.shop_id,
        start_time: this.search.start_time ,
        end_time: this.search.end_time,
      };
      
      // this.$router.push({ name: "预备会员明细", query });
      let route = this.$router.resolve({ name: "预备会员明细", query });
		  window.open(route.href, '_blank');
    },
    // 跳转事件
    clickOnline(row,id){
      this.$router.push("./entranceShop?shop_id="+row.shop_id + '&source_id='+id  + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
    },
    renderHeader(h, {column}) {
      switch(column.label)
      {
        case "预备会员顾客":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '预备会员顾客',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "新增会员数":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '新增会员数',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.hand{
  cursor:pointer;
}
.btnBox{
  text-align: right;
}
</style>
